import React from 'react';
import { CheckCircleOutlined, ExclamationCircleOutlined, WarningOutlined } from '@ant-design/icons';

import { Theme } from '@allenai/varnish';

import { Score, ScoreDisplayNameMap } from '../api/Answer';

interface ScoreIconProps {
    score: Score;
    className?: string;
}

export const ScoreIcon = ({ className, score }: ScoreIconProps) => {
    switch (score) {
        case Score.Correct:
            return (
                <CheckCircleOutlined
                    className={className}
                    style={{ color: Theme.color.G8.hex, fontSize: '1.1rem' }}
                    aria-label={ScoreDisplayNameMap[Score.Correct]}
                />
            );
        case Score.Incorrect:
            return (
                <WarningOutlined
                    className={className}
                    style={{ color: Theme.color.R8.hex, fontSize: '1.1rem' }}
                    aria-label={ScoreDisplayNameMap[Score.Incorrect]}
                />
            );
        case Score.PartiallyCorrect:
            return (
                <ExclamationCircleOutlined
                    className={className}
                    style={{ color: Theme.color.O7.hex, fontSize: '1.1rem' }}
                    aria-label={ScoreDisplayNameMap[Score.PartiallyCorrect]}
                />
            );
        default:
            return null;
    }
};
