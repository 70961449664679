import React from 'react';
import styled from 'styled-components';
import { Header as VarnishHeader } from '@allenai/varnish';
import Menu from 'antd/es/menu';
import { Link } from '@allenai/varnish-react-router';
import { useLocation } from 'react-router';
import {
    TwitterSquareFilled,
    LinkedinFilled,
    MenuOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import { Button } from 'antd';

import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';

import MacawLogo from '../../assets/logo/macaw_solosmall.png';
import { AppRoute } from '../../AppRoute';

interface HeaderProps {
    routes: AppRoute[];
    isMobileDrawerOpen: boolean;
    setIsMobileDrawerOpen: (isOpen: boolean) => void;
}

const social = {
    link: encodeURIComponent('https://macaw.apps.allenai.org'),
    title: encodeURIComponent('Macaw'),
    message: encodeURIComponent(
        'Macaw: a new question answering system from AI2 that outperforms other popular language models while being an order of magnitude smaller.'
    ),
};

export const Header = ({ routes, isMobileDrawerOpen, setIsMobileDrawerOpen }: HeaderProps) => {
    const location = useLocation();
    const screens = useBreakpoint();
    const isMobile = !screens.lg;

    React.useEffect(() => {
        // close mobile drawer on route change
        setIsMobileDrawerOpen(false);
    }, [location]);

    return (
        <>
            <VarnishHeader>
                <VarnishHeader.Columns columns="auto 1fr 280px">
                    <VarnishHeader.Logo
                        label={<VarnishHeader.AppName>Macaw</VarnishHeader.AppName>}>
                        <SimpleLogo src={MacawLogo} alt="Bird head graphic" />
                    </VarnishHeader.Logo>
                    <span />
                    {isMobile ? (
                        <div>
                            {isMobileDrawerOpen ? (
                                <IconButton
                                    type="link"
                                    onClick={() => setIsMobileDrawerOpen(false)}>
                                    <CloseIcon />
                                </IconButton>
                            ) : (
                                <IconButton type="link" onClick={() => setIsMobileDrawerOpen(true)}>
                                    <MenuIcon />
                                </IconButton>
                            )}
                        </div>
                    ) : (
                        <OverflowHidden>
                            <StyledVarnishHeaderMenuColumns>
                                <Menu defaultSelectedKeys={[location.pathname]} mode="horizontal">
                                    {routes.map(({ path, label }) => (
                                        <Menu.Item key={path}>
                                            <Link to={path}>{label}</Link>
                                        </Menu.Item>
                                    ))}
                                    <Menu.Item key="twitter">
                                        <a
                                            href={`https://twitter.com/intent/tweet?url=${social.link}&text=${social.message}`}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <TwitterSquareFilled alt="Share on Twitter" />
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item key="linkedin">
                                        <a
                                            href={`http://www.linkedin.com/shareArticle?mini=true&url=${social.link}&title=${social.title}&summary=${social.message}`}
                                            target="_blank"
                                            rel="noopener noreferrer">
                                            <LinkedinFilled alt="Share on LinkedIn" />
                                        </a>
                                    </Menu.Item>
                                </Menu>
                            </StyledVarnishHeaderMenuColumns>
                        </OverflowHidden>
                    )}
                </VarnishHeader.Columns>
            </VarnishHeader>
            {isMobile && isMobileDrawerOpen && (
                <MobileNav>
                    <ul>
                        {routes.map(({ path, label }) => (
                            <MobileNavItem key={`mobile-${label}`}>
                                <Link to={path}>{label}</Link>
                            </MobileNavItem>
                        ))}
                        <MobileNavItem key="mobile-twitter">
                            <a
                                href={`https://twitter.com/intent/tweet?url=${social.link}&text=${social.message}`}
                                target="_blank"
                                rel="noopener noreferrer">
                                <TwitterSquareFilled alt="Share on Twitter" /> Share on Twitter
                            </a>
                        </MobileNavItem>
                        <MobileNavItem key="mobile-linkedin">
                            <a
                                href={`http://www.linkedin.com/shareArticle?mini=true&url=${social.link}&title=${social.title}&summary=${social.message}`}
                                target="_blank"
                                rel="noopener noreferrer">
                                <LinkedinFilled alt="Share on LinkedIn" /> Share on LinkedIn
                            </a>
                        </MobileNavItem>
                    </ul>
                </MobileNav>
            )}
        </>
    );
};

const SimpleLogo = styled.img`
    width: auto;
    height: 2em;
    line-height: 1;
    padding-right: ${({ theme }) => theme.spacing.md};
`;

const StyledVarnishHeaderMenuColumns = styled(VarnishHeader.MenuColumn)`
    margin-top: 2px;
`;

const OverflowHidden = styled.div`
    overflow: hidden;
`;

const IconButton = styled(Button)`
    padding: 0;
`;

const CloseIcon = styled(CloseOutlined)`
    > svg {
        height: 20px;
        width: auto;
    }
`;

const MenuIcon = styled(MenuOutlined)`
    > svg {
        height: 20px;
        width: auto;
    }
`;

const MobileNav = styled.nav`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
    transition: transform 150ms ease-in-out;
    z-index: ${({ theme }) => theme.zIndex.header - 1};
    padding: ${({ theme }) => theme.spacing.xl2};
    padding-top: ${({ theme }) => theme.spacing.xl4};
    background-color: ${({ theme }) => theme.palette.common.white};
`;

const MobileNavItem = styled.li`
    list-style-type: none;
    padding: ${({ theme }) => theme.spacing.lg} 0;
    font-size: 24px;
    > a {
        text-decoration: none;
        color: ${({ theme }) => theme.palette.text.primary};
    }
    > a:hover {
        text-decoration: none;
        color: ${({ theme }) => theme.palette.primary.default};
    }
`;

