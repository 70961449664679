import React from 'react';
import styled from 'styled-components';
import { Button, Select } from 'antd';

import { Capitalize } from './Typography';

const { Option } = Select;

interface FilterSelectProps {
    id: string;
    mode?: 'multiple' | 'tags';
    labelText: string;
    placeholderText: string;
    isLoading?: boolean;
    value: any;
    onChange: (value: any) => void;
    onClear: () => void;
    options: Array<{ key: string; value: any }>;
}

export const FilterSelect = ({
    id,
    mode,
    labelText,
    placeholderText,
    isLoading,
    value,
    onChange,
    onClear,
    options,
}: FilterSelectProps) => {
    // supports arrays and normal values
    const determineShouldShowClear = () => {
        if (Array.isArray(value)) {
            return value.length > 0;
        }
        return value != null;
    };
    const showClear = determineShouldShowClear();
    return (
        <>
            <SelectionLabel id={id}>{labelText}</SelectionLabel>
            <SelectionGroup>
                <StyledSelect
                    showSearch
                    mode={mode}
                    placeholder={placeholderText}
                    aria-labelledby={id}
                    showArrow
                    allowClear
                    loading={isLoading}
                    value={value}
                    onChange={onChange}>
                    {options.map(({ key, value }) => (
                        <Option key={key} value={value}>
                            <Capitalize>{key}</Capitalize>
                        </Option>
                    ))}
                </StyledSelect>
                {showClear && (
                    <ClearBtn type="link" onClick={() => onClear()}>
                        Clear
                    </ClearBtn>
                )}
            </SelectionGroup>
        </>
    );
};

const SelectionLabel = styled.label`
    display: block;
    margin-bottom: ${({ theme }) => theme.spacing.sm};
`;

const SelectionGroup = styled.div`
    display: grid;
    grid-template: 1fr / auto 1fr;
`;

const ClearBtn = styled(Button)`
    align-self: center;
    justify-self: flex-start;
`;

const StyledSelect = styled(Select)`
    width: fit-content;
    min-width: 15em;
    .ant-select-selection-placeholder {
        color: ${({ theme }) => theme.color.N8};
    }
`;
