import axios from 'axios';

import { CategoryOption } from './CategoryOption';

/**
 * This method fetches category options by hitting /api/categories, which is defined in
 * api.py. The provided query is serialized and sent across the wire as JSON.
 */
export function categories(): Promise<Array<CategoryOption>> {
    return axios.get<Array<CategoryOption>>('/api/categories').then((resp) => resp.data);
}
