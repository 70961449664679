import React, { useState, useEffect } from 'react';
import { Tag } from 'antd';
import styled from 'styled-components';
import { belowOrEqualTo } from '@allenai/varnish';

import { CardList } from '../../../components/CardList';
import { FilterDisplayNameMap, Filters } from '../../../api/Filter';
import { Score, ScoreDisplayNameMap } from '../../../api';
import { ScoreIcon } from '../../../components/ScoreIcon';
import { Capitalize } from '../../../components/Typography';

interface QuestionAnswerSectionProps {
    selectedCategories: string[];
    selectedFilter: string;
    onCategoryClose: (value: string) => void;
    onFilterClose: (value: string) => void;
    areItemsLoading: boolean;
    items: any[]; // TODO
}

export const QuestionAnswerSection = ({
    selectedCategories,
    selectedFilter,
    onCategoryClose,
    onFilterClose,
    areItemsLoading,
    items,
}: QuestionAnswerSectionProps) => {
    const [currentPage, setCurrentPage] = useState<number>(1);

    useEffect(() => {
        // reset the page to the beginning whenever the filters / categories change
        setCurrentPage(1);
    }, [selectedCategories, selectedFilter]);

    return (
        <>
            <Grid>
                <div>
                    {!!(selectedCategories.length || selectedFilter) && (
                        <>
                            <span>Filters applied: </span>
                            {!!selectedFilter && (
                                <StyledTag closable onClose={() => onFilterClose(selectedFilter)}>
                                    {FilterDisplayNameMap[selectedFilter as Filters]}
                                </StyledTag>
                            )}
                            {selectedCategories.map((selection) => (
                                <StyledTag
                                    key={selection}
                                    closable
                                    onClose={() => onCategoryClose(selection)}>
                                    <Capitalize>{selection}</Capitalize>
                                </StyledTag>
                            ))}
                        </>
                    )}
                </div>
                <div>
                    <LegendTitle>Answer key:</LegendTitle>
                    <Legend aria-hidden>
                        {[Score.Correct, Score.PartiallyCorrect, Score.Incorrect].map((score) => {
                            const displayName = ScoreDisplayNameMap[score];
                            return (
                                <LegendItem key={displayName}>
                                    <StyledScoreIcon>
                                        <ScoreIcon score={score} />
                                    </StyledScoreIcon>
                                    <p>{displayName}</p>
                                </LegendItem>
                            );
                        })}
                    </Legend>
                </div>
            </Grid>
            <CardList
                data={items}
                isLoading={areItemsLoading}
                currentPage={currentPage}
                onPageChange={(page: number) => {
                    setCurrentPage(page);
                }}
            />
        </>
    );
};

const LegendTitle = styled.p`
    margin: ${({ theme }) => theme.spacing.xxs};
    color: ${({ theme }) => theme.color.N8};
`;

const Legend = styled.div`
    display: grid;
    grid-gap: ${({ theme }) => theme.spacing.sm};
    grid-template: 1fr / auto auto auto;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.color.N3};
    padding: ${({ theme }) => theme.spacing.sm} ${({ theme }) => theme.spacing.lg};
    > p {
        margin-bottom: 0;
    }
`;

const LegendItem = styled.div`
    display: grid;
    grid-template 1fr / min-content auto;
    > p {
        margin: 0;
    }
`;

const StyledTag = styled(Tag)`
    margin-top: 0.5em;
`;

const StyledScoreIcon = styled.span`
    padding-right: ${({ theme }) => theme.spacing.md};
`;

const Grid = styled.div`
    display: grid;
    grid-template: 1fr / 0.5fr 0.5fr;
    grid-gap: 0 ${({ theme }) => theme.spacing.xl};
    align-items: end;
    > * {
        margin-bottom: ${({ theme }) => theme.spacing.xl};
    }
    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        grid-template: 1fr / 1fr;
        > * {
            margin-bottom: ${({ theme }) => theme.spacing.md};
        }
    }
`;
