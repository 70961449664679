export enum Score {
    Incorrect = 1,
    PartiallyCorrect = 2,
    Correct = 3,
}

export const ScoreDisplayNameMap = {
    [Score.Correct]: 'Correct',
    [Score.PartiallyCorrect]: 'Partially correct',
    [Score.Incorrect]: 'Incorrect',
};

export enum Source {
    Macaw = 'Macaw',
    Gpt3 = 'GPT-3',
}

export interface Answer {
    answer: string;
    score: Score;
    source: Source;
}
export interface ResponseData {
    id: string;
    question: string;
    answers: Answer[];
}
