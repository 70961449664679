import React from 'react';
import styled from 'styled-components';
import { belowOrEqualTo, logos } from '@allenai/varnish';

import { Bold } from '../../components/Typography';

export const About = () => {
    return (
        <PageWrapper>
            <h1>About Macaw</h1>
            <p>
                Macaw (Multi-angle c(q)uestion answering) is a versatile, generative
                question-answering (QA) system built by extensively training and tuning the T5
                pretrained language model. Macaw exhibits strong zero-shot performance on a wide
                range of question types. On a suite of 300 challenge questions. Macaw outperformed
                GPT-3 by over 10%, even though Macaw is an order of magnitude smaller (11 billion vs
                175 billion parameters). Macaw is{' '}
                <a href="https://github.com/allenai/macaw">publicly available for free</a>. You can
                read more about the Macaw model, how we built our dataset, and how we scored the
                models' answers in our preprint{' '}
                <a href="https://www.semanticscholar.org/paper/General-Purpose-Question-Answering-with-Macaw-Tafjord-Clark/e3480d9395e692833b722b2e957d51139985f310">
                    General-Purpose Question-Answering
                </a>{' '}
                with Macaw and on the{' '}
                <a href="https://medium.com/ai2-blog/general-purpose-question-answering-with-macaw-84cd7e3af0f7">
                    AI2 Blog
                </a>
                .
            </p>
            <h2>The models</h2>
            <ul>
                <li>
                    <ModelName>Macaw:</ModelName> The Macaw-11B model (see{' '}
                    <a href="https://github.com/allenai/macaw">https://github.com/allenai/macaw</a>
                    ).
                </li>
                <li>
                    <ModelName>GPT-3:</ModelName> The largest (175 billion parameter) Davinci
                    version of GPT-3 (see{' '}
                    <a href="https://arxiv.org/pdf/2005.14165.pdf">
                        https://arxiv.org/pdf/2005.14165.pdf
                    </a>
                    ), accessed through the API (with zero temperature and prompt "
                    {'Q: <question text>\nA:'}")
                </li>
            </ul>
            <AristoSection>
                <p>
                    A project from the <AristoLogo />{' '}
                    <a href="https://allenai.org/aristo/">Aristo Team</a>.
                </p>
            </AristoSection>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        margin: 0 ${({ theme }) => theme.spacing.md};
    }
`;

const ModelName = styled(Bold)`
    padding-right: 0.25em;
`;

const AristoSection = styled.div`
    margin: ${({ theme }) => theme.spacing.xl3} 0;
`;

const AristoLogo = styled(logos.Aristo)`
    vertical-align: middle;
`;
