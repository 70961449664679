import axios from 'axios';

export interface ScoreSummary {
    [modelName: string]: number;
    question_count: number;
}
/**
 * This method fetches score summary data by hitting /api/scoreSummary, which is defined in
 * api.py. The provided query is serialized and sent across the wire as JSON.
 */
export function scoreSummary(): Promise<ScoreSummary> {
    return axios.get<ScoreSummary>('/api/scoreSummary').then((resp) => resp.data);
}
