import axios from 'axios';

import { ResponseData } from './Answer';

import { Query } from './Query';

/**
 * This method fetches data by hitting /api/solve, which is defined in
 * api.py. The provided query is serialized and sent across the wire as JSON.
 */
export function questionAnswers(query: Query): Promise<ResponseData[]> {
    return axios.post<ResponseData[]>('/api/questionAnswers', query).then((resp) => resp.data);
}
