export enum Filters {
    MacawCorrect = 'macaw_answers_correctly',
    MacawIncorrect = 'macaw_answers_incorrectly',
    Gpt3Correct = 'gpt3_answers_correctly',
    Gpt3Incorrect = 'gpt3_answers_incorrectly',
    AllModelsCorrect = 'all_models_answer_correctly',
    AllModelsIncorrect = 'all_models_answer_incorrectly',
}

export const FilterDisplayNameMap = {
    [Filters.MacawCorrect]: 'Macaw answers correctly',
    [Filters.MacawIncorrect]: 'Macaw answers incorrectly',
    [Filters.Gpt3Correct]: 'GPT-3 answers correctly',
    [Filters.Gpt3Incorrect]: 'GPT-3 answers incorrectly',
    [Filters.AllModelsCorrect]: 'All models answer correctly',
    [Filters.AllModelsIncorrect]: 'All models answer incorrectly',
};
