/**
 * Representation of a query, which consists of a filter and category choices.
 */
export class Query {
    constructor(readonly filter: string = '', readonly categories: string[] = []) {}

    // defines how the URL will identify the parameters we want to store
    private static readonly filterURLKey = 'f';
    private static readonly categoriesURLKey = 'c';

    /**
     * Serializes the query to a URL appropriate representation.
     *
     * @returns {string}
     */
    toQueryString(): string {
        const params = new URLSearchParams();
        if (this.filter) {
            params.set(Query.filterURLKey, this.filter);
        }
        // we have to append each selection value in the choices array to the URL
        for (const selection of this.categories) {
            params.append(Query.categoriesURLKey, selection);
        }
        return params.toString();
    }

    /**
     * Factory that returns a Query instance based upon the provided URL search parameters.
     *
     * @param {string} searchParams
     *
     * @returns {Query}
     */
    public static fromQueryString(searchParams: string): Query {
        const params = new URLSearchParams(searchParams);
        return new Query(
            params.get(Query.filterURLKey) ?? '',
            params.getAll(Query.categoriesURLKey) ?? []
        );
    }
}
