import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { belowOrEqualTo } from '@allenai/varnish';

import { Query, questionAnswers, categories as apiCategories } from '../../api';
import { ResponseData } from '../../api/Answer';
import { FilterDisplayNameMap, Filters } from '../../api/Filter';
import { SummarySection } from './sections/SummarySection';
import { QuestionAnswerSection } from './sections/QuestionAnswerSection';
import { FilterSelect } from '../../components/FilterSelect';
import { CategoryOption } from '../../api/CategoryOption';

export const Home = () => {
    const history = useHistory();
    /**
     * Local State
     */
    // options for the drop down (pulled from API)
    const [categoryOptions, setCategoryOptions] = useState<CategoryOption[]>([]);
    const [areCategoryOptionsLoading, setAreCategoryOptionsLoading] = useState<boolean>(true);

    // currently selected categories to filter by
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    // options for the drop down
    const filterOptions = [
        {
            key: FilterDisplayNameMap[Filters.MacawCorrect],
            value: Filters.MacawCorrect,
        },
        {
            key: FilterDisplayNameMap[Filters.MacawIncorrect],
            value: Filters.MacawIncorrect,
        },
        {
            key: FilterDisplayNameMap[Filters.Gpt3Correct],
            value: Filters.Gpt3Correct,
        },
        {
            key: FilterDisplayNameMap[Filters.Gpt3Incorrect],
            value: Filters.Gpt3Incorrect,
        },
        {
            key: FilterDisplayNameMap[Filters.AllModelsCorrect],
            value: Filters.AllModelsCorrect,
        },
        {
            key: FilterDisplayNameMap[Filters.AllModelsIncorrect],
            value: Filters.AllModelsIncorrect,
        },
    ];
    // currently selected filter
    const [selectedFilter, setSelectedFilter] = useState<string>('');

    // results
    const [areItemsLoading, setAreItemsLoading] = useState<boolean>(true);
    const [items, setItems] = useState<ResponseData[]>([]);

    /**
     * API Requests
     */
    useEffect(() => {
        fetchQuestionAnswers();
        fetchCategoryOptions();
    }, [location]);

    const fetchCategoryOptions = () => {
        setAreCategoryOptionsLoading(true);
        apiCategories()
            .then((options) => {
                setCategoryOptions(options);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => setAreCategoryOptionsLoading(false));
    };

    // Submits a call to the API for a given query as defined in the URL parameters
    const fetchQuestionAnswers = () => {
        const query = Query.fromQueryString(location.search);

        // remove empty question and answer options from the form so that the user knows they're not valid
        const cleanedQuery = new Query(
            query.filter.trim(),
            query.categories.filter((category) => category.trim() !== '')
        );
        setSelectedCategories(cleanedQuery.categories);
        setSelectedFilter(cleanedQuery.filter);

        setAreItemsLoading(true);

        // Note: validation is occurring on the backend, so errors will be thrown if there are any invalid inputs
        questionAnswers(query)
            .then((responseAnswer) => {
                setItems(responseAnswer);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                setAreItemsLoading(false);
            });
    };

    const updateURL = (newFilter: string, newCategories: string[]) => {
        // We add the query params to the URL, so that users can link to
        // our demo and share noteworthy cases, edge cases, etc.
        const query = new Query(newFilter === '' ? undefined : newFilter, newCategories);
        // pushing this new URL will automatically trigger a new query (see the 'useEffect' function above)
        history.push(`/?${query.toQueryString()}`);
    };

    return (
        <PageWrapper>
            <Section>
                <SummarySection
                    selectionChildren={
                        <>
                            <SelectionWrapper>
                                <FilterSelect
                                    id="categoriesLabel"
                                    mode="multiple"
                                    labelText="Choose one or more question categories:"
                                    placeholderText="All"
                                    isLoading={areCategoryOptionsLoading}
                                    value={selectedCategories}
                                    onChange={(values: string[]) => {
                                        setSelectedCategories(
                                            Array.from(new Set(selectedCategories.concat(values)))
                                        );
                                        updateURL(selectedFilter, values);
                                        fetchQuestionAnswers();
                                    }}
                                    options={categoryOptions.map(({ category, count }) => ({
                                        key: `${category} ${selectedFilter ? '' : `(${count})`}`,
                                        value: category,
                                    }))}
                                    onClear={() => {
                                        setSelectedCategories([]);
                                        updateURL(selectedFilter, []);
                                        fetchQuestionAnswers();
                                    }}
                                />
                            </SelectionWrapper>
                            <div>
                                <FilterSelect
                                    id="filtersLabel"
                                    labelText=" Filter questions by:"
                                    placeholderText="All"
                                    value={selectedFilter === '' ? undefined : selectedFilter}
                                    onChange={(value) => {
                                        const newFilter = value as string;
                                        setSelectedFilter(newFilter);
                                        updateURL(newFilter, selectedCategories);
                                        fetchQuestionAnswers();
                                    }}
                                    options={filterOptions}
                                    onClear={() => {
                                        setSelectedFilter('');
                                        updateURL('', selectedCategories);
                                        fetchQuestionAnswers();
                                    }}
                                />
                            </div>
                        </>
                    }
                />
            </Section>
            <Section hideTopMargin={true}>
                <QuestionAnswerSection
                    selectedCategories={selectedCategories}
                    selectedFilter={selectedFilter}
                    onCategoryClose={(closedCategory) => {
                        const newCategories = selectedCategories.filter(
                            (c) => c !== closedCategory
                        );
                        setSelectedCategories(newCategories);
                        updateURL(selectedFilter, newCategories);
                        fetchQuestionAnswers();
                    }}
                    onFilterClose={() => {
                        setSelectedFilter('');
                        updateURL('', selectedCategories);
                        fetchQuestionAnswers();
                    }}
                    areItemsLoading={areItemsLoading}
                    items={items}
                />
            </Section>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        margin: 0 ${({ theme }) => theme.spacing.md};
    }
    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.sm)} {
        margin: 0 ${({ theme }) => theme.spacing.sm};
    }
`;

export const Section = styled(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ hideTopMargin, ...rest }) => <div {...rest} />
)<{
    hideTopMargin?: boolean;
}>`
    margin: ${({ hideTopMargin, theme }) => (hideTopMargin ? 'auto' : theme.spacing.xl)} 0;
    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        margin: ${({ theme }) => theme.spacing.md} 0;
    }
`;

const SelectionWrapper = styled.div`
    margin-bottom: ${({ theme }) => theme.spacing.lg};
`;
