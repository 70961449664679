import React from 'react';
import { belowOrEqualTo, Theme } from '@allenai/varnish';
import styled from 'styled-components';

import { Source } from '../api';

/**
 * Shared Chart Utility Components
 */

export const ChartWrapper = styled(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ showBorderBottom, showBorderTop, chartHeight, mobileChartHeight, ...rest }) => (
        <div {...rest} />
    )
)<{
    showBorderBottom?: boolean;
    showBorderTop?: boolean;
    chartHeight?: string;
    mobileChartHeight?: string;
}>`
    height: ${({ chartHeight }) => chartHeight || '275px'};
    max-width: 100%;
    padding: 0 0 ${({ theme }) => theme.spacing.xl} 0;
    ${({ showBorderTop, theme }) =>
        showBorderTop === undefined ? `border-top: 2px solid ${theme.palette.border.default};` : ''}
    ${({ showBorderBottom, theme }) =>
        showBorderBottom === undefined
            ? `border-bottom: 2px solid ${theme.palette.border.default};`
            : ''}
    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        height: ${({ mobileChartHeight }) => mobileChartHeight || '275px'};
    }
    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.md)} {
        max-width: initial;
    }
`;

export const ChartTitle = styled.h2`
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: 1.5rem;
`;

export const customChartColors = {
    [Source.Gpt3]: Theme.lightCategoricalColor.Orange.hex,
    [Source.Macaw]: Theme.lightCategoricalColor.Blue.hex,
};

export const CustomTooltip = styled.div`
    background-color: ${({ theme }) => theme.palette.common.white};
    padding: ${({ theme }) => theme.spacing.xs};
    border-radius: 5px;
    box-shadow: 0px 4px 16px rgba(10, 41, 57, 0.08);
`;
