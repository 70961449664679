import { Home } from './pages/home/Home';
import { AppRoute } from './AppRoute';
import { About } from './pages/about/About';

/**
 * An array capturing the available routes in your application. You can
 * add or remove routes here.
 */
export const ROUTES: AppRoute[] = [
    {
        path: '/',
        label: 'Demo',
        component: Home,
    },
    {
        path: '/about',
        label: 'About',
        component: About,
    },
];
