import React from 'react';
import styled from 'styled-components';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { Theme } from '@allenai/varnish';

import { ChartTitle, ChartWrapper, customChartColors, CustomTooltip } from './Chart';
import { Source } from '../api/Answer';

export interface FormattingData {
    [source: string]: {
        label: string;
        value: string | number;
    };
}
interface HorizontalBarChartProps {
    chartTitle: string;
    chartDescription: string;
    chartLabel?: string;
    data: BarDatum[];
    valueFormatter?: (val: number) => string;
    indexBy: string;
    keys: string[];
}

export const HorizontalBarChart: React.FC<HorizontalBarChartProps> = ({
    chartTitle,
    chartDescription,
    chartLabel,
    data,
    valueFormatter = (val) => `${val}`,
    indexBy,
    keys,
}) => {
    const nivoTheme = {
        ...Theme.nivo.theme.defaults,
        labelTextColor: undefined,
        borderColor: undefined,
    };
    return (
        <div>
            <ChartTitle>{chartTitle}</ChartTitle>
            <ChartWrapper showBorderBottom={false} showBorderTop={false}>
                <ResponsiveBar
                    {...nivoTheme}
                    enableGridY={false}
                    enableGridX={false}
                    layout="horizontal"
                    groupMode="grouped"
                    padding={0.25}
                    data={data}
                    keys={keys}
                    indexBy={indexBy}
                    colors={(bar) => {
                        return (
                            customChartColors[bar?.id as Source] ||
                            Theme.lightCategoricalColor.Blue.hex
                        );
                    }}
                    margin={{
                        // very specific sizing here because we want as little left margin as possible so that the bar chart
                        // has more space. This is to ensure that the labels on small bar chart values have enough padding
                        // on mobile.
                        left: 95,
                    }}
                    labelSkipWidth={100} // labelSkipWidth = minimum bar size needed for a normal label
                    label={(barData) => `${barData.id ?? ''}`}
                    layers={[
                        'grid',
                        'axes',
                        'bars',
                        'markers',
                        'legends',
                        'annotations',
                        ({ bars, labelSkipWidth }) => {
                            return (
                                <g>
                                    {bars.map(({ width, height, x, y, data }) => {
                                        // only show this custom outer label on bars that are too small
                                        return width < labelSkipWidth ? (
                                            <text
                                                key={`${data.id}-${x}-${y}`}
                                                transform={`translate(${width + 10}, ${
                                                    y + height / 2
                                                })`}
                                                textAnchor="left"
                                                dominantBaseline="central">{`${
                                                data.id ?? ''
                                            }`}</text>
                                        ) : null;
                                    })}
                                </g>
                            );
                        },
                    ]}
                    minValue={0}
                    maxValue={100}
                    valueFormat={(val) => valueFormatter(val)}
                    labelTextColor={Theme.palette.common.white.hex}
                    axisLeft={{ tickSize: 0, tickPadding: 20 }}
                    ariaLabel={chartDescription}
                    barAriaLabel={(e) => `${e.id}: ${e.formattedValue}`}
                    axisBottom={{
                        tickSize: 0,
                        tickPadding: 5,
                        tickRotation: 0,
                        format: () => '',
                    }}
                    tooltip={(input) => {
                        const source = input?.id;
                        const label = input?.label ? `${input?.label}: ` : '';
                        const formattingData =
                            (input?.data?.data as unknown as FormattingData) ?? {};
                        const value = formattingData[source]?.label ?? input?.value;
                        return (
                            <CustomTooltip>
                                <span>{label}</span>
                                <strong>{value}</strong>
                            </CustomTooltip>
                        );
                    }}
                />
                <ChartLabel>{chartLabel}</ChartLabel>
            </ChartWrapper>
        </div>
    );
};

const ChartLabel = styled.p`
    text-align: center;
    padding-left: ${({ theme }) => theme.spacing.md};
`;
