import React, { useEffect, useState } from 'react';
import { belowOrEqualTo } from '@allenai/varnish';
import styled from 'styled-components';
import { BarDatum } from '@nivo/bar';

import { scoreSummary as apiScoreSummary, Source } from '../../../api';
import { FormattingData, HorizontalBarChart } from '../../../components/HorizontalBarChart';

export enum ChartGroups {
    Performance = 'Performance',
    Size = 'Size',
}
interface SummarySectionProps {
    selectionChildren: React.ReactNode;
}

const BILLION = 1000000000;
const sizeComparisonData = {
    [Source.Gpt3]: 175 * BILLION,
    [Source.Macaw]: 11 * BILLION,
};

export const SummarySection = ({ selectionChildren }: SummarySectionProps) => {
    // data for the average score chart
    const [scoreSummary, setScoreSummary] = useState<{ [model: string]: number }>({});
    const [totalQuestionCount, setTotalQuestionCount] = useState(0);

    /**
     * API Requests
     */
    useEffect(() => {
        fetchScoreSummary();
    }, [location]);

    const fetchScoreSummary = () => {
        apiScoreSummary()
            .then((summary) => {
                setTotalQuestionCount(summary.question_count);
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { question_count: deletedKey, ...data } = summary; // removing 'question_count' so we can process this into chart data
                setScoreSummary(data);
            })
            .catch((err) => {
                console.error(err);
            });
    };

    const combinedChartIndex = 'group';
    const combinedChartGroups = [ChartGroups.Performance, ChartGroups.Size];
    const combinedChartKeys = [Source.Macaw, Source.Gpt3];

    // assembling the combined data from the two data sources
    const combinedChartData = combinedChartGroups.map((group) => {
        const item: Record<string, unknown> = {
            [combinedChartIndex]: group,
        };
        combinedChartKeys.forEach((source) => {
            if (group === ChartGroups.Performance) {
                const score = scoreSummary[source] || 0;
                const scorePercentage = Math.floor((score / totalQuestionCount) * 100);
                // normalized value that determines the bar size
                item[source] = scorePercentage;
                // true value for displaying formatting information
                const data = {
                    [source]: {
                        value: scorePercentage, // true is same as normalized here since we're normalizing to 100%
                        label: `${scorePercentage}% correct`,
                    },
                } as FormattingData;
                item.data = Object.assign(data, item.data ? item.data : {});
            }
            if (group === ChartGroups.Size) {
                // normalized value that determines the bar size
                const originalValue = sizeComparisonData[source] || 0;
                const totalParameters = Object.values(sizeComparisonData).reduce(
                    (prev, next) => prev + next,
                    0
                );
                const normalizedValue = Math.floor((originalValue / totalParameters) * 100);
                item[source] = normalizedValue;
                // true value for displaying formatting information
                const data = {
                    [source]: {
                        value: originalValue / BILLION,
                        label: `${originalValue / BILLION} billion parameters`,
                    },
                } as FormattingData;
                item.data = Object.assign(data, item.data ? item.data : {});
            }
        });
        return item;
    });

    return (
        <>
            <StyledH1>Introducing: Macaw</StyledH1>
            <Grid>
                <DescriptionArea>
                    <p>
                        Macaw is a high-performance question-answering (QA) model capable of
                        outperforming other popular current language models, all while being an
                        order of magnitude smaller. This demo allows you to explore Macaw's answers
                        and compare them to those of the popular GPT-3 language model on a benchmark
                        set of questions.
                    </p>
                    {selectionChildren}
                </DescriptionArea>
                <HorizontalBarChartArea>
                    <HorizontalBarChart
                        chartTitle="Model Size vs Model Performance"
                        chartDescription={`Comparison between the Macaw and GPT-3 models. Macaw has ${Math.floor(
                            sizeComparisonData[Source.Gpt3] - sizeComparisonData[Source.Macaw]
                        )} less parameters than GPT-3 but does ${Math.floor(
                            Math.floor((scoreSummary[Source.Macaw] / totalQuestionCount) * 100) -
                                Math.floor((scoreSummary[Source.Gpt3] / totalQuestionCount) * 100)
                        )}% better in correctly answering the ${totalQuestionCount} questions.`}
                        data={combinedChartData as BarDatum[]}
                        keys={combinedChartKeys}
                        indexBy={combinedChartIndex}
                    />
                </HorizontalBarChartArea>
            </Grid>
        </>
    );
};

const StyledH1 = styled.h1`
    margin: 0;
    margin-bottom: ${({ theme }) => theme.spacing.xl};
`;

const DescriptionArea = styled.div`
    grid-area: description;
`;

const HorizontalBarChartArea = styled.div`
    grid-area: chart;
    .ant-carousel {
        @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.md)} {
            padding: 0 ${({ theme }) => theme.spacing.sm} 0 0;
        }
    }
`;

const Grid = styled.div`
    display: grid;
    grid-template: 'description chart' 1fr / calc(50% - ${({ theme }) => theme.spacing.xl}) 50%;
    grid-gap: 0 ${({ theme }) => theme.spacing.xl};
    @media ${({ theme }) => belowOrEqualTo(theme.breakpoints.lg)} {
        /* move the chart to be on top of the description in a single column */
        grid-template:
            'chart' auto
            'description' auto / 100%;
        grid-gap: ${({ theme }) => theme.spacing.xl};
    }
`;
