import React from 'react';
import styled from 'styled-components';
import { Card, List, Typography } from 'antd';
import Parser from 'html-react-parser';
import { above } from '@allenai/varnish';

import { ResponseData } from '../api/Answer';
import { ScoreIcon } from './ScoreIcon';
import { Bold } from './Typography';

interface CardListProps {
    data: ResponseData[];
    isLoading: boolean;
    currentPage: number;
    onPageChange: (page: number) => void;
}

export const CardList = ({ data, isLoading = false, currentPage, onPageChange }: CardListProps) => {
    const renderItem = ({ id, question, answers }: ResponseData) => (
        <StyledListItem key={`item-${id}`}>
            <StyledCard
                title={
                    <Typography.Paragraph
                        ellipsis={{
                            rows: 3,
                            expandable: true,
                            symbol: 'more',
                            tooltip: true,
                        }}>
                        <Bold aria-hidden="true">Q:</Bold> {question}
                    </Typography.Paragraph>
                }>
                {answers.map(({ answer, score, source }) => (
                    <div key={`${id}-${source}`}>
                        <CardContent key={answer}>
                            {score !== null ? <StyledScoreIcon score={score} /> : null}
                            <StyledParagraph
                                ellipsis={{
                                    rows: 3,
                                    expandable: true,
                                    symbol: 'more',
                                    tooltip: true,
                                }}>
                                {Parser(String(answer))}
                            </StyledParagraph>
                            <div className="grid-blank" />
                            <Bold>{source}</Bold>
                        </CardContent>
                    </div>
                ))}
            </StyledCard>
        </StyledListItem>
    );
    return (
        <>
            <StyledList
                loading={isLoading}
                grid={{
                    gutter: 16,
                    xs: 1,
                    sm: 1,
                    md: 2,
                    lg: 3,
                    xl: 3,
                    xxl: 3,
                }}
                id="id"
                dataSource={data}
                renderItem={(data) => renderItem(data as ResponseData)}
                pagination={{
                    pageSize: 6,
                    showSizeChanger: false,
                    current: currentPage,
                    onChange: onPageChange,
                }}
            />
        </>
    );
};

const StyledList = styled(List)`
    @media ${({ theme }) => above(theme.breakpoints.md)} {
        .ant-col {
            height: 95%;
        }
    }
`;

const StyledListItem = styled(List.Item)`
    @media ${({ theme }) => above(theme.breakpoints.md)} {
        height: 100%;
    }
    .ant-card-bordered {
        border-color: ${({ theme }) => theme.color.N9};
        @media ${({ theme }) => above(theme.breakpoints.md)} {
            height: 100%;
        }
    }
`;

const StyledCard = styled(Card)`
    .ant-card-head-title {
        overflow: visible;
        white-space: initial;
        text-overflow: initial;
    }
`;

const StyledScoreIcon = styled(ScoreIcon)`
    align-self: self-start;
    padding-top: ${({ theme }) => theme.spacing.xxs};
`;

const CardContent = styled.div`
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: min-content auto;
    grid-gap: 0 ${({ theme }) => theme.spacing.md};
    margin: ${({ theme }) => theme.spacing.md} 0;
    /* handling really long answers or number */
    overflow: hidden;
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
`;

const StyledParagraph = styled(Typography.Paragraph)`
    margin-bottom: 0 !important;
`;
