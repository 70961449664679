/**
 * This is the top-level component that defines your UI application.
 *
 * This is an appropriate spot for application wide components and configuration,
 * stuff like application chrome (headers, footers, navigation, etc), routing
 * (what urls go where), etc.
 *
 * @see https://github.com/reactjs/react-router-tutorial/tree/master/lessons
 */

import React, { useState } from 'react';
import styled from 'styled-components';
import { BrowserRouter, Route } from 'react-router-dom';
import { Content, Footer, Layout } from '@allenai/varnish';

import { ROUTES } from './routes.const';
import { Header } from './components/header/Header';

export const App = () => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    return (
        <BrowserRouter>
            <Route path="/">
                <Layout bgcolor="white">
                    <Header
                        routes={ROUTES}
                        isMobileDrawerOpen={isDrawerOpen}
                        setIsMobileDrawerOpen={setIsDrawerOpen}
                    />
                    <StyledContent main isMobileDrawerOpen={isDrawerOpen}>
                        {ROUTES.map(({ path, component }) => (
                            <Route key={path} path={path} exact component={component} />
                        ))}
                    </StyledContent>
                    <Footer />
                </Layout>
            </Route>
        </BrowserRouter>
    );
};

export const StyledContent = styled(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ isMobileDrawerOpen, children, ...rest }) => <Content {...rest}>{children}</Content>
)<{
    isMobileDrawerOpen?: boolean;
    children: React.ReactNode;
}>`
    display: ${({ isMobileDrawerOpen }) => (isMobileDrawerOpen ? 'none' : 'initial')};
    overflow-x: hidden;
`;
